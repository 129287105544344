<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <logo-with-text />
      </b-link>
      <!-- /Brand logo-->
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Forgot password PrepShipHub" />
        </div>
      </b-col>
      <!-- /Left Text-->
      <!-- Forgot password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1">
            {{ title }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ message }}
          </b-card-text>
          <form v-if="!resetKeySended" method="post" @submit="handleSubmit($event)">
            <dx-util-form
              ref="sendResetLinkFormRef"
              :form-data="sendResetLinkFormData"
              :col-count="1"
              :show-colon-after-label="true"
              label-location="top"
            >
              <dx-util-item :editor-options="emailOptions" data-field="email">
                <dx-util-required-rule message="Email is required" />
                <dx-util-email-rule message="Email is invalid" />
              </dx-util-item>
              <dx-util-group-item :col-count="2">
                <dx-util-button-item
                  :button-options="backLoginButtonOptions"
                  horizontal-alignment="left"
                />
                <dx-util-button-item
                  :button-options="sendButtonOptions"
                  horizontal-alignment="right"
                />
              </dx-util-group-item>
            </dx-util-form>
          </form>
          <form v-else method="post" @submit="handleSubmitReset($event)">
            <dx-util-form
              ref="sendResetPasswordFormRef"
              :form-data="sendResetPasswordFormData"
              :col-count="1"
              :show-colon-after-label="true"
              label-location="top"
            >
              <dx-util-item :editor-options="passwordOptions" data-field="password">
                <dx-util-required-rule message="Password is required" />
                <dx-util-pattern-rule
                  :pattern="passwordPattern"
                  message="Your password must contain at least one uppercase, one lowercase, one special character and one digit"
                />
              </dx-util-item>
              <dx-util-item :editor-options="passwordOptions" data-field="confirmPassword">
                <dx-util-required-rule message="Confirm Password is required" />
                <dx-util-compare-rule :comparison-target="passwordComparison" message="Password and Confirm Password do not match" />
              </dx-util-item>
              <dx-util-button-item
                :button-options="resetButtonOptions"
                horizontal-alignment="right"
              />
            </dx-util-form>
          </form>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
import LogoWithText from '@core/layouts/components/LogoWithText.vue'
import store from '@/store/index'
import userAccountService from '@/http/requests/system/userAccountService'

export default {
  components: {
    LogoWithText,
  },
  data() {
    return {
      sendResetLinkFormData: {
        email: '',
      },
      sendResetPasswordFormData: {
        email: '',
        password: '',
        confirmPassword: '',
      },
      query: {},
      passwordPattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/,
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      title: 'Forgot Password?',
      message: 'Enter your email and we\'ll send you instructions to reset your password',
    }
  },
  computed: {
    resetKeySended() {
      return (typeof (this.query.code) !== 'undefined' && this.query.code !== null && this.query.code !== '')
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    emailOptions() {
      return {
        mode: 'email',
        inputAttr: { style: 'text-transform: lowercase' },
      }
    },
    sendButtonOptions() {
      return {
        text: 'Send reset link',
        type: 'success',
        useSubmitBehavior: true,
      }
    },
    resetButtonOptions() {
      return {
        text: 'Reset Password',
        type: 'success',
        useSubmitBehavior: true,
      }
    },
    backLoginButtonOptions() {
      return {
        text: 'Back to login',
        type: 'normal',
        icon: 'chevronprev',
        stylingMode: 'outlined',
        onClick: e => {
          this.$router.push({ name: 'auth-login' })
        },
      }
    },
    passwordOptions() {
      return {
        mode: 'password',
        readOnly: this.isCreated,
      }
    },
  },
  created() {
    this.query = { ...this.$route.params }
    const result = (typeof (this.query.code) !== 'undefined' && this.query.code !== null && this.query.code !== '')
    if (result) {
      this.title = 'Reset Password'
      this.message = 'Enter your new password'
    }
  },
  methods: {
    passwordComparison() {
      return this.sendResetPasswordFormData.password
    },
    handleSubmit(e) {
      const form = this.$refs.sendResetLinkFormRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        userAccountService.requestPasswordReset(this.sendResetLinkFormData.email).then(result => {
          if (result.status === 200) {
            this.message = 'Password reset mail send successfully. Please check your mail.'
          }
        })
      }
      e.preventDefault()
    },
    handleSubmitReset(e) {
      const form = this.$refs.sendResetPasswordFormRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        const resetPasswordForm = {
          key: this.query.code,
          newPassword: this.sendResetPasswordFormData.password,
        }
        userAccountService.finishPasswordReset(resetPasswordForm).then(result => {
          if (result.status === 200) {
            this.$router.push('/login')
          }
        })
      }
      e.preventDefault()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
