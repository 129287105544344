import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  // create new user
  async register(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.system.accountController.registerAccount(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  // activate account
  async activateAccount(code) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.accountController.activateAccount(code))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  // activate account
  async resendActivationCode(email) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.accountController.resendActivationCode(email))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async getAccount() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.accountController.getAccount())
        .then(response => {
          resolve({
            data: response.data,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async requestPasswordReset(email) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.system.accountController.requestPasswordReset(), email)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  // gönderilen key email ve new password şeklinde bir devam yolu olacak.
  async finishPasswordReset(KeyAndPasswordVM) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.system.accountController.finishPasswordReset(), KeyAndPasswordVM)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

}
