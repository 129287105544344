<template>
  <b-img
    :src="logo"
    alt="PrepShipHub"
    :height="height"
  />
</template>
<script>
export default {
  props: {
    height: {
      type: String,
      default: '64px',
    },
  },
  data: () => ({
    // eslint-disable-next-line global-require
    logo: require('@/assets/images/logo/prep-with-text.svg'),
  }),
}
</script>
